@import url(https://fonts.googleapis.com/css?family=Raleway:700,600);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300&subset=latin,latin-ext);
@import 'variables';
@import 'bootstrap';
@import 'font-awesome';

html {
  height: 100%;
}

body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  display: block;
  font-family: 'Open Sans', sans-serif;
}

.bg-white {
  background-color: white;
}

.bg-dark-green {
  background-color: $color-dark-green;
}

.bg-light-green {
  background-color: lighten($color-dark-green, 85%);
}

.bg-dark-brown {
  background-color: $color-dark-brown;
}

.bg-light-brown {
  background-color: lighten($color-light-brown, 60%);
}

// Navbar
.navbar-brand {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 34px;
  color: #604030 !important;
}

.navbar-home {
  background-color: rgba(255, 255, 255, 0.7);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 24px;

  h1 {
    padding-top: 6px;
  }

  button {
    padding-top: 13px;
    color: #604030;
  }

  button:focus {
    outline: none;
  }

  .nav-link {
    color: darken(#fff, 60%);
    font-weight: 300;
  }

  .nav-link.active {
    color: #604030;
    font-weight: 400;

    @include plain-hover-focus {
      background-color: transparent;
    }
  }

  .nav-stacked {
    border-top: 1px solid #604030;
  }
}

.navbar-content {
  background: none;
  background-color: #825641;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 24px;

  h1 {
    color: #ffe7c5 !important;
    padding-top: 6px;
  }

  button {
    padding-top: 13px;
    color: #ffe7c5;
  }

  button:focus {
    outline: none;
  }

  .nav-link {
    color: lighten(#825641, 30%);
    font-weight: 300;
  }

  .nav-link.active {
    color: #ffe7c5;
    font-weight: 400;

    @include plain-hover-focus {
      background-color: transparent;
    }
  }

  .nav-stacked {
    border-top: 1px solid #ffe7c5;
  }
}

.home-section {
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('images/background.jpg');
  background-size: cover;
  display: block;

  #welcome {
    height: 100%;
    width: 100%;
    display: table;

    div {
      display: table-cell;
      vertical-align: middle;
      text-align: center;

      h1 {
        font-family: "Raleway", sans-serif;
        font-weight: 600;
        font-size: 50px;
        color: #fff;
        padding-top: 50px;
        padding-bottom: 45px;
        text-align: left;

        @media screen and (max-width: 992px) {
          font-size: 40px;;
          padding-bottom: 35px;
        }

        @media screen and (max-width: 768px) {
          font-size: 30px;
          padding-bottom: 25px;
        }

        @media screen and (max-width: 544px) {
          font-size: 30px;
          padding-bottom: 20px;
        }
      }

      p {
        font-family: 'Open Sans', sans-serif;
        font-size: 30px;
        font-weight: 300;
        margin: 18px 0;
        color: rgba(255, 255, 255, 1);
        padding-bottom: 45px;
        text-align: left;

        @media screen and (max-width: 992px) {
          font-size: 25px;
          padding-bottom: 30px;
        }

        @media screen and (max-width: 768px) {
          font-size: 20px;
          padding-bottom: 25px;
        }

        @media screen and (max-width: 544px) {
          padding-bottom: 20px;
          font-weight: 400;
        }

      }
    }
  }
}

.content-section {
  padding-top: 50px 0;

  h1, h2, h3, h4, h5 {
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    margin: 30px 0;
    color: #604030;
  }

  h1 {
    font-size: 50px;
    margin: 30px 0;
  }

  p, li {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 28.8px;
    font-weight: 300;
    margin: 18px 0;
  }

  .form-group {

    input, textarea, button {
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: 300;
      margin: 30px 0;
    }

    textarea {
      height: 182px;
      resize: none;
    }
  }

  button {
    color: #ffe7c5;
    background-color: #604030;
  }
}

footer {
  text-align: center;
  background-color: #604030;
  color: #ffe7c5;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 16px;

  h1, h2, h3, h4, h5 {
    font-family: "Raleway", sans-serif;
    font-weight: 700;
  }

  h4 {
    font-size: 40px;
  }

  address {
    margin: 10px 0;
  }

  a {
    color: #ffe7c5;
  }
}